<script lang="ts">
import FloatingVue, { PopperWrapper } from 'floating-vue';

import 'floating-vue/dist/style.css';

FloatingVue.options.distance = 7;
FloatingVue.options.instantMove = true;
FloatingVue.options.themes.tooltip.delay = {
  show: 0,
  hide: 0,
};

export default {
  ...PopperWrapper,
  vPopperTheme: 'tooltip',
};
</script>

<style>
.v-popper--theme-tooltip .v-popper__inner {
  @apply bg-gray-darkest text-base font-bold rounded shadow-sm p-20 max-w-sm;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  @apply border-gray-darkest;
}

.v-popper__arrow-outer {
  @apply -top-[1px];
}

.v-popper__popper.v-popper__popper--shown,
.v-popper__popper.v-popper__popper--hidden {
  @apply duration-[0ms];
}

.v-popper__popper[data-popper-placement^='top-start'] .v-popper__arrow-container {
  @apply !left-15;
}
.v-popper__popper[data-popper-placement^='left-start'] .v-popper__arrow-container {
  @apply !top-30;
}
</style>
